import { FullStory, init } from '@fullstory/browser';

export const fsStart = () => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      console.log('init full story');
      // init full story once user has logged in
      init({ orgId: 'o-2288PY-na1' });
      return true;
    }
  } catch (e) {
    console.log('failed to start full story', e);
  }
};
export const fsSetIdentify = (user: any) => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      console.log('set identity');
      // init full story once user has logged in
      FullStory('setIdentity', {
        uid: user.id,
        properties: {
          applicantId: user.id,
          applicant_name: `${user.first_name} ${user.last_name}`,
          email: `${user.email}`,
          env: process.env.REACT_APP_ENVIRONMENT,
          fp_app: 'applicant-client',
        },
      });
      // FullStory('trackEvent', {
      //   name: 'Applicant Logged In',
      //   properties: {
      //     applicantId: user.id,
      //     applicant_name: `${user.first_name} ${user.last_name}`,
      //     email: `${user.email}`,
      //   },
      // });
    }
  } catch (e) {
    console.log('failed to set identify', e);
  }
};

export const fsTrackEvent = (name: string, props: any) => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT !== 'local') {
      FullStory('trackEvent', {
        name,
        properties: props,
      });
    }
  } catch (e) {
    console.log('failed to track event', e);
  }
};
